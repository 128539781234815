import { defineStore } from "pinia";

export const useStore = defineStore("container", {
    state: () => ({
        tag: null as string | null,
        profile: null as VenueProfile | null,
        isPro: true as boolean,
        rc: [] as string[],
        blobsList: [] as any[],
        recipes: [] as any[],
        homeContent: "" as string,
        aboutContent: "" as string,
        mapUrl: "" as string,
        loading: false as boolean,
        timestamp: null as any | null,
        version: null as any | null
    }),

    getters: {
        facebook: (state) => {
            let social = state.profile?.publish?.social?.find((s) => s.name === "facebook");
            return social?.url;
        },

        instagram: (state) => {
            let social = state.profile?.publish?.social?.find((s) => s.name === "instagram");
            return social?.url;
        },

        twitter: (state) => {
            let social = state.profile?.publish?.social?.find((s) => s.name === "twitter");
            return social?.url;
        },

        youtube: (state) => {
            let social = state.profile?.publish?.social?.find((s) => s.name === "youtube");
            return social?.url;
        },

        pinterest: (state) => {
            let social = state.profile?.publish?.social?.find((s) => s.name === "pinterest");
            return social?.url;
        },

        delivery: (state) => state.profile?.publish?.delivery,

        order: (state) => state.profile?.publish?.order,
        reservation: (state) => state.profile?.publish?.reservation,


        recipesMap: state => {
            let keyGetter = (item: any) => (item.category && state.rc.indexOf(item.category) >= 0) ? item.category : "Other Items";
            let g = groupBy(state.recipes, keyGetter);
            let cats = JSON.parse(JSON.stringify(state.rc));
            cats.push("Other Items");
            return new Map([...g.entries()].sort((a, b) => {
                let a_index = cats.indexOf(a[0]);
                let b_index = cats.indexOf(b[0]);
                if (a_index > b_index) return 1;
                else if (a_index < b_index) return -1;
                else return 0;
            }));
        },

        //    menuItemFormat: (state) => state.profile?.publish?.menuItemFormat,
        labelFormat: (state) => state.profile?.publish?.label_format,
        publishNutrition: (state) => state.profile?.publish?.publish_nutrition,

        heroImage: (state) => {
            let image = state.blobsList.find((blob) => blob.name.startsWith("assets/hero"));
            //    return "";
            return image ? `https://fcpsites.blob.core.windows.net/${state.tag}/${image.name}` : null;
        },

        staffImage: (state) => {
            let image = state.blobsList.find((blob) => blob.name.startsWith("assets/staff"));
            return image ? `https://fcpsites.blob.core.windows.net/${state.tag}/${image.name}` : null;
        },

        pdfMenu: (state) => {
            return (state.blobsList.find((blob) => blob.name === "assets/menu.pdf")) ?
                `https://fcpsites.blob.core.windows.net/${state.tag}/assets/menu.pdf` : null;
        }

    },

    actions: {
        async setStore(tag?: string | null) {
            this.loading = true;
            if (tag && typeof tag !== undefined && this.tag !== tag || !this.profile) {
                const result: any = await $fetch(`/api/containers/${tag}`).catch((err) => { console.log("(store)err: ", err) });

                if (result) {
                    this.tag = <string>tag;
                    this.profile = result.profile;
                    this.rc = result.rc?.categories || [];
                    this.blobsList = result.blobsList;
                    this.mapUrl = result.mapEmbedSource;
                    this.timestamp = result.timestamp;
                    this.version = result.timestamp ? new Intl.DateTimeFormat("en-AU", {
                        timeStyle: "medium",
                        dateStyle: "short",
                        timeZone: "Australia/Sydney"
                    }).format(new Date(result.timestamp)) : null;
                    if (this.blobsList && this.blobsList.length > 0) {
                        await this.setHomeContent();
                        this.downloadRecipes();
                        this.setAboutContent();
                        //              this.processImages();
                        this.checkPro();
                    }
                    this.setFavicon();
                }
            }
            this.loading = false;
        },

        async downloadRecipes() {
            this.recipes = [];
            let recipes = this.blobsList?.filter((blob) => blob.name.startsWith("recipes/"));
            if (recipes && recipes.length > 0) {
                let result = await Promise.all(recipes.map(async (recipe) => {
                    let recipeName = recipe.name.split("/")[1];
                    let blob = await $fetch(`https://fcpsites.blob.core.windows.net/${this.tag}/recipes/${encodeURIComponent(recipeName)}`)
                        .catch((err) => { console.log("(store)error fetching recipe: ", err) });

                    if (blob) {
                        let data = JSON.parse(await (<Blob>blob).text());
                        if (data && data.name) return data;
                    }
                }));

                this.recipes = result.filter((r) => r);


                // for (let recipe of recipes) {
                //     let recipeName = recipe.name.split("/")[1];
                //     let blob = await $fetch(`https://fcpsites.blob.core.windows.net/${this.tag}/recipes/${encodeURIComponent(recipeName)}`)
                //         .catch((err) => { console.log("(store)error fetching recipe: ", err) });

                //     if (blob) {
                //         let data = JSON.parse(await (<Blob>blob).text());
                //         if (data && data.name) this.recipes.push(data);
                //     }
                // }
            }
        },

        setFavicon() {
            let links = [];
            let favicon = this.blobsList.find((blob) => blob.name === "assets/favicon.ico");
            if (favicon) links.push({ rel: "icon", type: "image/x-icon", href: `https://fcpsites.blob.core.windows.net/${this.tag}/assets/favicon.ico` });
            let favicon16 = this.blobsList.find((blob) => blob.name === "assets/favicon-16x16.png");
            if (favicon16) links.push({ rel: "icon", type: "image/png", sizes: "16x16", href: `https://fcpsites.blob.core.windows.net/${this.tag}/assets/favicon-16x16.png` });
            let favicon32 = this.blobsList.find((blob) => blob.name === "assets/favicon-32x32.png");
            if (favicon32) links.push({ rel: "icon", type: "image/png", sizes: "32x32", href: `https://fcpsites.blob.core.windows.net/${this.tag}/assets/favicon-32x32.png` });
            if (links.length > 0) useHead({ link: links });
        },

        async setHomeContent() {
            let name = this.blobsList.find((blob) => blob.name === "assets/home.txt");
            if (name) {
                let blob = await $fetch(`https://fcpsites.blob.core.windows.net/${this.tag}/assets/home.txt`)
                    .catch((err) => { console.log("(store)error fetching home content: ", err) });
                if (blob) {
                    let content = await (<Blob>blob).text();
                    if (content) this.homeContent = content;
                }
            }
        }, 
        
        async setAboutContent() {
            let name = this.blobsList.find((blob) => blob.name === "assets/about.txt");
            if (name) {
                let blob = await $fetch(`https://fcpsites.blob.core.windows.net/${this.tag}/assets/about.txt`)
                    .catch((err) => { console.log("(store)error fetching home content: ", err) });
                if (blob) {
                    let content = await (<Blob>blob).text();
                    if (content) this.aboutContent = content;
                }
            }
        },

        async checkPro() {
            let profile = this.blobsList.find((blob) => blob.name === "profile");
            if (profile && profile.metadata && profile.metadata.userId) {
                if (profile.metadata.pro) {
                    let pro = JSON.parse(profile.metadata.pro);
                    if (pro && pro.timestamp) {
                        let now = this.timestamp || Date.now();
                        let diff = now - pro.timestamp;
                        if (diff < 1000 * 60 * 60 * 24) this.isPro = pro.value;
                        else {
                            let result: any = await $fetch(`/api/proCheck/${this.tag}`).catch((err) => { console.log("(store)err: ", err) });
                            if (result) this.isPro = result.isPro;
                        }
                    } else {
                        console.log("no timestamp!");
                    }
                } else {
                    let result: any = await $fetch(`/api/proCheck/${this.tag}`).catch((err) => { console.log("(store)err: ", err) });
                    if (result) this.isPro = result.isPro;
                }
            }

        }
    }
})


