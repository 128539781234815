
export const isOfType = <T>(
    varToBeChecked: any,
    propertyToCheckFor: keyof T
): varToBeChecked is T =>
    (varToBeChecked as T)[propertyToCheckFor] !== undefined;


export function formatNum(num: number, digits: number) {
    switch (digits) {
        case 0:
            return Math.round(num);
        case 1:
            return Math.round(num * 10) / 10;
        case 2:
            return Math.round(num * 100) / 100;
        case 3:
            return Math.round(num * 1000) / 1000;
        case 4:
            return Math.round(num * 10000) / 10000;
        case 5:
            return Math.round(num * 100000) / 100000;
        case 6:
            return Math.round(num * 1000000) / 1000000;
        case 7:
            return Math.round(num * 10000000) / 10000000;
        case 8:
            return Math.round(num * 100000000) / 100000000;
        case 9:
            return Math.round(num * 1000000000) / 1000000000;
        case 10:
            return Math.round(num * 10000000000) / 10000000000;
        default:
            return parseFloat(num.toFixed(1));
    }
}

export function groupBy(list: any[], keyGetter: (item: any) => string): Map<any, any> {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

export function getThumbsLink(name: string, tag: string, size: string = "large") {
    if(!name) return "/placeholder.webp"
    let filename = name.replace("large/", "");
    filename = filename.replace("medium/", "");
    return `https://fcpsites.blob.core.windows.net/${tag}/${size}/${encodeURIComponent(filename)}`;
}


export function batchArray(array: any[], count: number = 10) {
    let batches = [];
    let batch: any[] = [];
    array.forEach(i => {
        batch.push(i);
        if (batch.length === count) {
            batches.push(batch.slice(0));
            batch = [];
        }
    });
    if (batch.length) batches.push(batch.slice(0));
    return batches;
}